import React, { useEffect, useState } from "react";
import { API_URL } from "../config";
import axios from "axios";

function AuthPage() {
  const [verificationStatus, setVerificationStatus] = useState(null);

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const token = queryParams.get("token");

    if (token) {
      const verificationUrl = `${API_URL}/api/activate/?token=${token}`;

      async function fetchVerification() {
        try {
          const res = await axios.get(verificationUrl);
          console.log(res.data);
          if (res.status === 200 || res.status === 201) {
            setVerificationStatus("verified");
            if (res.data?.redirect === true) {
              window.location.href = "/activation-success";
            } else {
              window.location.href = "/";
            }
          } else {
            setVerificationStatus("error");
          }
        } catch (error) {
          console.error("Verification failed", error);
          setVerificationStatus("error");
        }
      }

      fetchVerification();
    } else {
      setVerificationStatus("invalid");
    }
  }, []);

  return (
    <div>
      {verificationStatus === "verified" ? (
        <h1>Vas nalog je verifikovan</h1>
      ) : verificationStatus === "error" ? (
        <h1>Greska prilikom verifikacije</h1>
      ) : verificationStatus === "invalid" ? (
        <h1>Neispravan token u URL-u</h1>
      ) : (
        <h1>Verifikacija u toku...</h1>
      )}
    </div>
  );
}

export default AuthPage;
